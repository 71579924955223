const CALCULATION_STATES = {
  RUN: "RUN",
  QUEUED: "QUEUED",
  FAIL: "FAIL",
  SUCCESS: "SUCCESS",
  UNKNOWN: "UNKNOWN",
};
const COLOR_NAME_BY_STATE = {
  RUN: "primary",
  STILL_RUN: "primary",
  QUEUED: "warning",
  FAIL: "red",
  SUCCESS: "positive",
  UNKNOWN: "info",
};
const ICON_NAME_BY_STATE = {
  RUN: "memory",
  STILL_RUN: "memory",
  QUEUED: "list_alt",
  FAIL: "error",
  SUCCESS: "check_circle",
  UNKNOWN: "directions",
};

 const ions = 'opt_ions'
 const full = 'opt_full'
 const mechanic = 'stability_mech'
 const dynamic = 'stability_dyn'
 const energy = 'energy'
const CALCULATION_UNIQ_ID = {
    ions: ions,
    full: full,
    dynamic: dynamic,
    mechanic: mechanic,
    energy: energy,
}

function getCalculationFromCard(card, calcType, state) {
  const target = (card.calculations || []).filter((calc) => calc.calculation_type === calcType && calc.state === state)
  if (target.length !== 0) return target[0]
  return {}
}

export {
  CALCULATION_STATES,
  COLOR_NAME_BY_STATE,
  ICON_NAME_BY_STATE,
  CALCULATION_UNIQ_ID,
  getCalculationFromCard
};
